import {VueSlideToggle} from 'vue-slide-toggle';
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "current-orders",
    components: {
        VueSlideToggle
    },
    props: ['order'],
    data() {
        return {
            openItem: false,
            newPrice: 0,
            newPriceSum: 0,
            totalPrice: 0,
            currentPrice: 0,
            beauticianDiscount:0
        };
    },
    computed: {
        ...mapGetters({
            repeatOrder: 'order/repeatOrder',
            certificateResponse: 'order/certificateStatus',
            usedBonuses: 'basket/setBonuses',
            discount: 'basket/discount',

        }),
        totalAmount() {
            return this.order.products.reduce((accumulator, currentValue) => {


                if (currentValue.weight.sale.new_price) {
                    this.newPrice = currentValue.weight.sale.new_price
                    this.newPriceSum = currentValue.count * this.newPrice
                } else {
                    this.currentPrice = currentValue.price
                }

                if (currentValue.weight.sale.new_price) {
                    return this.totalPrice = accumulator + (currentValue.weight.sale.new_price * currentValue.count)
                } else {
                    return this.totalPrice = accumulator + (currentValue.price * currentValue.count)
                }


            }, 0);


        },
        totalDiscount() {
            console.log(7);
            let totalSum = 0
            if (this.certificateResponse) {
                console.log(1);
                totalSum = totalSum + parseInt(this.certificateResponse.data.amount)
            }
            if (this.usedBonuses) {
                console.log(2);
                totalSum = totalSum + this.usedBonuses
            }
            if (this.discount) {
                console.log(3);
                totalSum = totalSum + this.discount.your_discount
            }
            if (this.beauticianDiscount) {
                console.log(4);
                totalSum = totalSum + this.beauticianDiscount
            }
            return totalSum
        },

    },
    methods: {
        ...mapActions({
            fetchRepeatOrder: 'order/REPEAT_ORDER',
        }),
        ...mapMutations({
            resetBasket: 'basket/RESET_BASKET',
            addToBasket: 'basket/ADD_ITEM_TO_BASKET'
        }),
        repeat(id) {
            this.fetchRepeatOrder(id).then(() => {
                this.resetBasket()

                for (let i in this.repeatOrder) {

                    this.addToBasket({
                        select_count: this.repeatOrder[i].count,
                        select_price: this.repeatOrder[i].weight.price,
                        select_volume: this.repeatOrder[i].weight,
                        ...this.repeatOrder[i]
                    })
                }

                this.$router.push({name: 'buy-flow'}).catch(() => {
                })
                setTimeout(() => {
                    this.colorUnSet()
                }, 20)
            })
        },
        colorSet() {
            // document.getElementById("bg_wrapper").style.visibility = 'visible'
            // this.$refs.bg_slider.style.zIndex = 25;
            // this.$refs.bg_slider.style.position = 'sticky';
            // this.$refs.bg_slider.style.backgroundColor = '#fff'
            // this.$refs.bg_slider.style.boxShadow = '0px 0px 27px 20px rgba(34, 60, 80, 0.2)'
            // document.getElementById("header").style.position = 'sticky';
            // document.getElementById("breadcrumbs_wrap").style.paddingTop = '8px'
        },
        colorUnSet() {
            document.getElementById("bg_wrapper").style.visibility = 'hidden'
            this.$refs.bg_slider.style = 'z-index: auto;'
            document.getElementById("breadcrumbs_wrap").style.paddingTop = '120px'
            document.getElementById("header").style.position = 'fixed';
        },
    },
    created() {

    }

};
