import {VueSlideToggle} from 'vue-slide-toggle';
import Accordion from "./accordion/index.vue"
import {mapActions, mapGetters} from "vuex";

export default {
  name: "current-orders",
  components: {
    VueSlideToggle,
    Accordion,
  },
  data() {
    return {
    };
  },
  computed:{
    ...mapGetters({
      orders:'order/currentOrders'
    })
  },
  watch:{
    '$route'(){
      if(this.$route.name === 'current-orders')
        this.fetchCurrentOrders('in_progress')
      else this.fetchCurrentOrders('')
    }
  },
  created() {
    if(this.$route.name === 'current-orders')
    this.fetchCurrentOrders('in_progress')
    else this.fetchCurrentOrders('')
  },
  methods: {
    ...mapActions({
      fetchCurrentOrders:'order/GET_CURRENT_ORDERS'
    })
  }

};
